import Sdk from 'api.digitalpages.module.sdk.api';
import axios from 'axios'
import pagarme from 'pagarme'

async function getContent({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  var roles = Sdk.Api.authorization.user.roles;
  var isReviewer = false;
  if (roles) {
    for (var i = 0; i < roles.length; i++) {
      if (roles[i].type === "ReviewAdmin" || roles[i].type === "ReviewContributor") {
        isReviewer = true;
      }
    }
  }
  let response;

  if (isReviewer) {
    response = Sdk.dynamic.bridgeUrl(`cms/v1.0/learning/interaction/uid/${payload.interaction.uid}/management/content`);
  } else {
    response = Sdk.dynamic.bridgeUrl(`cms/v1.0/learning/interaction/uid/${payload.interaction.uid}/content`);
  }


  var customOptions = btoa(JSON.stringify({
    "activity_uid": payload.activity.uid,
    "register_uid": payload.register_uid
  }))


  onChangeState({
    loading: false,
    response,
    video_conference_url: `${window.location.origin}/meet.html?access_token=${Sdk.Api.authorization.credential.accessToken}&custom_options=${customOptions}`
  });
}

async function register({ payload, onChangeState }) {
  var body = {
    status: payload.status,
  };

  if (payload.score !== undefined) body.score = payload.score;

  try {
    let activityObj = null;

    if (payload.status.toLowerCase() === "Incomplete".toLowerCase()) {
      activityObj = await Sdk.dynamic.bridge(`cms/v1.0/learning/interaction/uid/${payload.interaction_uid}/details`, null, 'GET');
    }

    // fix(PLT-314): Ao visualizar uma atividade já completada o status muda para "Incomplete"
    // impedindo ou causando erros na geração do certificado.
    // Somente deve mudar o status da atividade para "Incomplete" se o status não for "Completed" ou "Passed".
    if (activityObj === null || (activityObj && (activityObj.status.toLowerCase() !== 'Completed'.toLowerCase() || activityObj.status.toLowerCase() !== 'Passed'.toLowerCase()))) {
      if (activityObj !== null && (activityObj.status.toLowerCase() === 'Completed'.toLowerCase() || activityObj.status.toLowerCase() === 'Passed'.toLowerCase())) {
        body.status = activityObj.status;
      }
      const response = await Sdk.dynamic.bridge(`cms/v1.0/learning/interaction/uid/${payload.interaction_uid}`, body, 'PUT');
      onChangeState({
        success: true,
        response
      });
    }
  } catch(error) {
    return onChangeState({
      error: "Você atingiu o número máximo de tentativas na atividade.",
      success: false
    })
  }
}

async function buyProduct({ payload, onChangeState }) {
  const {
    card_number,
    card_holder_name,
    card_expiration_date,
    card_cvv,
    billing_city,
    billing_state,
    billing_country,
    billing_name,
    billing_neighborhood,
    billing_street,
    billing_street_number,
    billing_complementary_address,
    billing_zipcode,
    installments,
    userRegister,
    productInfo,
    type
  } = payload
  const card = { card_number, card_holder_name, card_expiration_date, card_cvv }
  const paymentConnector = Sdk.Api.authorization.activeProject.connectors.find(connector => connector.type === 'Payment_1')
  if (!paymentConnector) {
    return onChangeState({
      error: 'Algo deu errado na transação. Por favor, tente novamente mais tarde.',
      success: false,
    })
  }
  Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${userRegister.uid}`, null, 'POST').then(() => {}, () => {})
  try {
    const paymentConnectorInfo = await Sdk.dynamic.bridge(`auth/v1.1/connector/uid/${paymentConnector.uid}`)
    const {encryption_key} = paymentConnectorInfo.configuration
    const client = await pagarme.client.connect({ encryption_key })
    const cardHash = await client.security.encrypt(card)
    const billingInfo = {
      type,
      billing_name,
      billing_country,
      billing_state,
      billing_city,
      billing_neighborhood,
      billing_street,
      billing_street_number,
      billing_complementary_address,
      billing_zipcode,
      installments,
      card_hash: cardHash
    }
    const response = await Sdk.dynamic.bridge(`store/v1.0/purchase/product/uid/${productInfo.uid}`, billingInfo, 'POST')
    onChangeState({
      success: true,
      response: response
    })
  } catch(error) {
    return onChangeState({
      error: 'Algo deu errado na transação. Por favor, tente novamente mais tarde.',
      success: false,
    })
  }
}
async function getCepInfo({payload, onChangeState}) {
  const {cep} = payload
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

  onChangeState({
    success: true,
    response
  });
}
async function getActivityStatus({ payload, onChangeState }) {
  const response = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${payload.registerUid}/status`);

  onChangeState({
    response
  });
}

async function duration({payload, onChangeState}) {
  var body = {
    duration: payload.duration,
  };

  try {
    let activityObj = null;

    activityObj = await Sdk.dynamic.bridge(`cms/v1.0/learning/interaction/uid/${payload.interactionUid}/details`, null, 'GET');

    // É necessário informar o estado atual da atividade, pois caso omitido será alterado automaticamente pela API para "NotAttempted".
    if (activityObj !== null) {
      body.status = activityObj.status;
    }

    const response = await fetch(`${Sdk.Api.domain}/cms/v1.0/learning/interaction/uid/${payload.interactionUid}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        "Project-Key": Sdk.Api.projectKey,
        "Api-Env": Sdk.Api.enviroment,
        "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`,
        "Content-Type": "application/json"
      },
      keepalive: true
    });

    onChangeState({
      success: true,
      response
    });
  } catch(error) {
    return onChangeState({
      error: "Você atingiu o número máximo de tentativas na atividade.",
      success: false
    })
  }
}

export default {
  getActivityStatus,
  getContent,
  register,
  buyProduct,
  getCepInfo,
  duration
}