import React, { useState, useEffect } from 'react';

import { HashRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles';

import Routes from './routes';

import { Provider } from 'react-redux';
import { store } from './store';


// import { projectUid, version, projectKey } from '~/config';

// import Sdk from 'api.digitalpages.module.sdk.api';
//import SdkRequest from 'api.digitalpages.module.request.api';

import styled, { ThemeProvider } from 'styled-components';
import theme from './theme';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import GoogleRecaptchaBadge from "~/components/google-recaptcha-badge/google-recaptcha-badge";
import {AppContext} from "~/contexts/app-context";
import Sdk from "api.digitalpages.module.sdk.api";

const WrapperLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [ready,] = useState(true);
  const [showReCaptchaBadge, setShowReCaptchaBadge] = useState(false);
  const [reCaptchaKey, setReCaptchaKey] = useState("");

  const toggleReCaptchaBadge = (show) => {
    setShowReCaptchaBadge(show);
  }
  useEffect(() => {
    window.addEventListener('scroll', function() {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight)) {
        window.dispatchEvent(new Event("@rdpplayer.event(scrolltobottom)"))
      }
    });

    const loadAppReCaptchaKey = async () => {
      const directory = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET");
      if (directory) {
        const reCaptchaConnector = directory.connectors.find(connector => connector.name === "ReCaptcha_1");
        if (reCaptchaConnector && reCaptchaConnector.configuration.public_key) {
          setReCaptchaKey(reCaptchaConnector.configuration.public_key);
        }
      }
    }

    loadAppReCaptchaKey();
  }, []);

  return (
    <Provider store={store}>
      <AppContext.Provider value={{showReCaptchaBadge, toggleReCaptchaBadge}}>
        <GoogleReCaptchaProvider
          reCaptchaKey={reCaptchaKey}
          container={{
            element: "app-recaptcha-badge-container",
            parameters: {
              badge: 'bottomright',
            }
          }}
        >
          <Router>
            <ThemeProvider theme={theme}>
              <div id="root" className="rdp-rdpplayer">
                <GlobalStyle />
                {ready && <Routes />}
              </div>
            </ThemeProvider>
          </Router>
          <GoogleRecaptchaBadge show={showReCaptchaBadge} />
        </GoogleReCaptchaProvider>
      </AppContext.Provider>
    </Provider>
  ); 
}

export default App;
